import React from 'react';
import cx from 'classnames';
import Flower from '../../../images/mia-flower.svg';
import Section from 'components/common/Section';

import './styles.sass';
import Image from '../utils/Image';

class DoctorForCarousel extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <div
        className="doctor-list-div-wrapper d-flex"
        key={data.position}
      >
        <div className="doctor-list-div d-flex flex-row justify-content-between">
          <div className="doctor-profile d-flex flex-column align-items-center">
            <Image filename={data.pictures.round} className="doctor-img" alt="photo de l'expert Anna Barbieri MD, FACOG" />
            <div className="doctor-text-div">
              <span className="doctor-title">{data.name}</span>
              <span>{data.practitionerInfo.bio}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DoctorForCarousel;
