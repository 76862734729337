import React from 'react';
import DoctorList from 'components/common/DoctorList';
import { chunk } from 'lodash';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';

import Chevron from 'images/open-iconic/svg/custom-arrow.svg';

import { practitioners } from '../data/doctors';
import DoctorForCarousel from '../DoctorForCarousel';

const TherapistCarousel = ({ therapist = practitioners }) => {
  if (therapist.length === 0) {
    return null;
  }
  const practitionersArray = therapist.concat(therapist).concat(therapist);
  const slicesBy3 = chunk(practitionersArray, 3);
  const previousNode = <Chevron width={32} height={32} />;
  const nextNode = <Chevron width={32} height={32} className="left-arrow-therapist-carousel" />;
  return (
    <div className="mb-4 mb-lg-5 videowall-scroll-div-wrapper">
      <div className="x-scroll-div d-flex d-lg-none">
        {therapist.map((doctor) => (
          <div key={doctor.id} className="d-inline-block px-3">
            <DoctorForCarousel data={doctor} />
          </div>
        ))}
      </div>
      <Carousel
        indicators={false}
        className="d-none d-lg-flex"
        prevIcon={previousNode}
        nextIcon={nextNode}
        interval={null}
        controls
      >
        {slicesBy3.map((slice) => (
          <Carousel.Item key={slice.id} className="">
            <Row className="mx-0">
              {slice.map((doctor) => (
                <Col
                  xs={4}
                  key={doctor.position}
                >
                  <DoctorForCarousel data={doctor} />
                </Col>
              ))}
            </Row>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default TherapistCarousel;
