import React from 'react';
import Section from 'components/common/Section';

import './styles.sass';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Image from '../utils/Image';

class DoctorList extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <Section className="doctor-list-section">
        <Container fluid>
          <h2 className="h2-title text-white text-center">DES EXPERTES POUR VOUS ACCOMPAGNER</h2>
          <Row className="d-flex justify-content-center">
            <div className="d-flex carousel-doctor">
              {data.map((doctor) => (
                <div
                  className="doctor-list-div-wrapper d-flex"
                  key={doctor.position}
                >
                  <div className="doctor-list-div d-flex flex-row justify-content-between">
                    <div className="doctor-profile d-flex flex-column align-items-center">
                      <Image filename={doctor.pictures.gray} className="doctor-img" alt="photo de l'expert Anna Barbieri MD, FACOG" />
                      <div className="doctor-text-div">
                        <span className="doctor-title">{doctor.name}</span>
                        <span>{doctor.medicalTeamInfo.lines}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Row>
        </Container>
      </Section>
    );
  }
}

export default DoctorList;
